<template>
  <div class="content-wrapper help-center">
    <h1 class="mb-5">Order Management</h1>
    <b-card class="mb-4">
      <div class="mt-1 d-flex justify-content-between">
        <div class="d-flex flex-column">
          <div class="mb-1">
            <b-input-group size="sm" class="mb-2">
              <b-input-group-prepend is-text>
                <b-icon icon="search"></b-icon>
              </b-input-group-prepend>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Search"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="d-flex flex-column">
            <b-form-select
              :disabled="loadingLoadList"
              @change="loadList"
              v-model="statusListSelected"
              :options="statusList"
            ></b-form-select>
            <span style="color:gray; font-size:12px"
              >{{
                loadingLoadList
                  ? "Caricamento in corso..."
                  : computed_List.length + " risultati trovati"
              }}
            </span>
          </div>
        </div>

        <div>
          <b-row>
            <b-col>
              <b-button
                size="sm"
                class="mb-2"
                v-b-toggle.collapse-advancedFilter
                :disabled="advancedFilter_visible"
              >
                <b-icon icon="filter" aria-hidden="true"></b-icon> Advanced
                Filter
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button size="sm" class="mb-2 d-flex">
                <b-icon icon="download" class="mr-2"></b-icon>
                <download-excel :data="items">
                  Download File CSV
                </download-excel>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
    <b-collapse
      id="collapse-advancedFilter"
      v-model="advancedFilter_visible"
      class="mt-2"
    >
      <b-card class="mb-4">
        <div class="d-flex">
          <span>Advanced Filter</span>
          <b-button-close v-b-toggle.collapse-advancedFilter class="ml-auto" />
        </div>
        <b-row class="mt-3">
          <b-col>
            <b-form-select
              v-model="roleTypeListSelected"
              :options="roleTypeList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="areaTypeListSelected"
              :options="areaTypeList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="departmentTypeListSelected"
              :options="departmentTypeList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="clusterTypeListSelected"
              :options="clusterTypeList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="typeListSelected"
              :options="typeList"
            ></b-form-select>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <b-card>
      <SkeletonTable v-if="loadingLoadList"></SkeletonTable>
      <b-table
        v-else
        hover
        :items="computed_List"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        fixed
      >
        <template #cell(data)="row">
          {{ formatDate(row.value) }}
        </template>
        <template #cell(status_text)="row">
          <div class="d-flex justify-content-center">
            <b-icon-circle-fill
              class="my-1"
              :class="{
                'text-success': row.item.id_status == 3,
                'text-warning': row.item.id_status == 2,
                'text-danger': row.item.id_status == 1,
                'text-dark': row.item.it_status == 4,
              }"
            />
          </div>
          <div style="font-size: 0.75rem;text-align: center;">
            <span>{{ row.value }}</span>
          </div>
        </template>

        <template #cell(role)="row">
          {{ getRoleName(row.item.id_role) }}
        </template>
        <template #cell(area)="row">
          {{ getAreaName(row.item.id_area) }}
        </template>
        <template #cell(department)="row">
          {{ getDepartmentName(row.item.id_department) }}
        </template>
        <template #cell(cluster)="row">
          {{ getClusterName(row.item.id_cluster) }}
        </template>
        <template #cell(prodotto)="row">
          {{ getTypeListName(row.item.product_id) }}
        </template>
        <!-- <template #cell(need_reply)="row">
          <b-icon-chat-fill
            class="ml-4"
            :class="{ not_reply: !row.item.need_reply }"
          />
        </template> -->

        <template #cell(actions)="row">
          <b-icon-pencil-fill
            v-b-toggle.sidebar-chat
            @click="prepareChat(row.item)"
            class="mx-3 pointer text-primary"
          >
          </b-icon-pencil-fill>
          <b-icon-trash-fill
            @click="
              currentItem = row.item
              showDeleteModal = true
            "
            class="pointer"
          ></b-icon-trash-fill>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="mt-4 mb-1"
      ></b-pagination>
    </b-card>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-chat"
      right
      shadow
      lazy
      @hidden="resetSidebar"
    >
      <Chat
        :plugin="'salesChat'"
        :item="currentEntry"
        @update-reply="updateReply"
        @close-ticket="closeTicket"
      />
    </b-sidebar>
    <b-modal
      v-model="showDeleteModal"
      title="Attention!"
      ok-title="Confirm"
      cancel-title="Cancel"
      @ok="deleteItem(currentItem.id)"
      >Do you really want to delete the selected element?
    </b-modal>
  </div>
</template>

<script>
import Chat from "../../components/Chat.vue"
import { datetimeToString } from "@/utils/DatetimeUtils.js"
import AxiosService from "../../axiosServices/AxiosService"
import SkeletonTable from "../../components/SkeletonTable.vue"
export default {
  components: { Chat, SkeletonTable },
  service: null,
  data() {
    return {
      currentItem: null,
      showDeleteModal: false,
      items: [],
      fields: [
        { key: "data", label: "Date" },
        { key: "created_by_string", label: "Created By" },
        { key: "role", label: "Role" },
        { key: "area", label: "Area" },
        { key: "department", label: "Department" },
        { key: "cluster", label: "Cluster" },
        { key: "prodotto", label: "Product" },
        { key: "numeroCommessa", label: "Order Number" },
        { key: "margineValue", label: "Margine" },
        { key: "nomeCliente", label: "Client Name" },
        { key: "status_text", label: "Status" },
        { key: "actions", label: "Actions" },
      ],
      currentPage: 1,
      perPage: 10,
      filter: "",
      advancedFilter_visible: false,
      currentEntry: null,

      typeList: [{ value: null, text: "All items" }],
      typeListSelected: null,

      statusList: [
        { value: 0, text: "All status" },
        { value: 3, text: "Confirmed" },
        { value: 1, text: "Registered" },
        { value: 2, text: "Verifying" },
        { value: 4, text: "Annullato" },
      ],
      statusListSelected: 1,

      roleTypeList: [{ value: null, text: "All Roles" }],
      roleTypeListSelected: null,

      areaTypeList: [{ value: null, text: "All Areas" }],
      areaTypeListSelected: null,

      departmentTypeList: [{ value: null, text: "All Departments" }],
      departmentTypeListSelected: null,

      clusterTypeList: [{ value: null, text: "All Clusters" }],
      clusterTypeListSelected: null,

      loadingLoadList: false,
    }
  },
  created() {
    this.service = new AxiosService("User")
  },
  mounted() {
    this.service.readCustomEndpoint("Option/UserRole/16").then((res) => {
      let result = res
      result.forEach((element) => this.roleTypeList.push(element))
    })

    this.service
      .readCustomEndpoint("AdvancedTargeting/GetAreas")
      .then((res) => {
        let result = res.map((item) => ({ value: item.id, text: item.nome }))
        result.forEach((element) => this.areaTypeList.push(element))
      })

    this.service
      .readCustomEndpoint("AdvancedTargeting/GetDepartments")
      .then((res) => {
        let result = res.map((item) => ({ value: item.id, text: item.nome }))
        result.forEach((element) => this.departmentTypeList.push(element))
      })

    this.service
      .readCustomEndpoint("AdvancedTargeting/GetClusters")
      .then((res) => {
        let result = res.map((item) => ({ value: item.id, text: item.nome }))
        result.forEach((element) => this.clusterTypeList.push(element))
      })

    this.service.readCustomEndpoint("SalesChat/GetProducts").then((res) => {
      let result = res.map((item) => ({ value: item.id, text: item.nome }))
      result.forEach((element) => this.typeList.push(element))
    })

    this.loadList()
    //   this.timer = setInterval(this.loadList, 30000);
    //   this.service.getOptions("HelpDeskType").then((data) => {
    //     this.typeList = this.typeList.concat(data);
    //   });
    // this.service.getOptions("HelpDeskStatus").then((data) => {
    //   this.statusList = this.statusList.concat(data);
    // });
  },
  methods: {
    deleteItem(id) {
      const serviceDELETE = new AxiosService("SalesChat/DeleteOrder/")
      serviceDELETE
        .delete(id)
        .then((response) => {
          if (response) {
            this.items = this.items.filter(
              (currentItem) => id !== currentItem.id
            )
            this.$successToast()
          } else {
            this.$errorToast()
            return false
          }
        })
        .catch(() => {
          this.$errorToast()
          return false
        })
    },
    async prepareChat(item) {
      this.currentEntry = { ...item }
      this.currentEntry.titolo = item.nomeCliente
    },
    loadList() {
      this.loadingLoadList = true
      if (this.currentEntry === null) {
        this.service
          .readCustomEndpoint(
            `SalesChat/GetOrdersNew/${this.statusListSelected}`
          )
          .then((res) => (this.items = res))
          .finally(() => (this.loadingLoadList = false))
      }
    },
    resetSidebar() {
      this.currentEntry = null

      // this.roleTypeList.splice(1);
      // this.areaTypeList.splice(1);
      // this.departmentTypeList.splice(1);
      // this.clusterTypeList.splice(1);
      // this.typeList.splice(1);

      // this.roleTypeListSelected = null;
      // this.areaTypeListSelected = null;
      // this.departmentTypeListSelected = null;
      // this.clusterTypeListSelected = null;
      // this.typeListSelected = null;
      this.loadList()
    },

    getRoleName(idRole) {
      const role = this.roleTypeList.find((x) => x.value === idRole)
      return role ? role.text : "-"
    },
    getAreaName(idArea) {
      const area = this.areaTypeList.find((x) => x.value === idArea)
      return area ? area.text : "-"
    },
    getDepartmentName(idDepartment) {
      const department = this.departmentTypeList.find(
        (x) => x.value === idDepartment
      )
      return department ? department.text : "-"
    },
    getClusterName(idCluster) {
      const cluster = this.clusterTypeList.find((x) => x.value === idCluster)
      return cluster ? cluster.text : "-"
    },
    getTypeListName(idType) {
      const type = this.typeList.find((x) => x.value === idType)
      return type ? type.text : "-"
    },
    updateReply() {
      if (this.currentEntry) {
        this.currentEntry["need_reply"] = false
      }
    },
    formatDate(datetime) {
      return datetimeToString(datetime)
    },
    closeTicket() {
      if (this.currentEntry) {
        const item = JSON.parse(JSON.stringify(this.currentEntry))
        item.id_status = 2
        if (item.id) {
          this.service
            .update(item)
            .then((response) => {
              if (response.id == item.id) {
                this.currentEntry.id_status = 2
                this.currentEntry.status_text = "Closed"
                this.$root.$emit("bv::toggle::collapse", "sidebar-chat")
                this.$successToast("Ticket closed")
              } else {
                this.$errorToast()
                return false
              }
            })
            .catch(() => {
              this.$errorToast()
              return false
            })
        }
      }
    },
  },
  computed: {
    totalRows() {
      return this.computed_List ? this.computed_List.length : 0
    },
    computed_List() {
      return this.items.filter((item) => {
        if (this.typeListSelected && item.product_id != this.typeListSelected)
          return false
        if (
          this.statusListSelected &&
          item.id_status != this.statusListSelected
        )
          return false
        if (
          this.roleTypeListSelected &&
          item.id_role != this.roleTypeListSelected
        )
          return false
        if (
          this.areaTypeListSelected &&
          item.id_area != this.areaTypeListSelected
        )
          return false
        if (
          this.departmentTypeListSelected &&
          item.id_department != this.departmentTypeListSelected
        )
          return false
        if (
          this.clusterTypeListSelected &&
          item.id_cluster != this.clusterTypeListSelected
        )
          return false
        return true
      })
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1
      },
    },
  },
}
</script>
